import React from "react";
import { Box, Paper, Typography, Stack, Grid, Button } from "@mui/material";
import { useTheme } from "../../context/ThemeContext";
import { useNavigate } from "react-router-dom";

export default function InventoryItem(props) {
	const { themeObj } = useTheme();
	const navigate = useNavigate();

	return (
		<Box sx={{ marginBottom: '5px' }}>
			<Button variant="text" sx={{ color: 'revert', textTransform: 'none', width: '100%', padding: 0, borderRadius: '5px', border: 'none' }} onClick={() => navigate(`/inventory/${props.item_id}`)}>
				<Paper sx={{ width: '100%', padding: '10px 20px', backgroundColor: themeObj.secondary }}>
					<Grid container rowSpacing={0.5}>
						<Grid item xs={12}>
							<Typography sx={{ overflow: "hidden", maxHeight: "56px", textWrap: "wrap", textOverflow: "ellipsis" }} width='calc(100% - 10px)' textAlign="left" fontSize="1.1rem" color={themeObj.title}>{props.item_name}</Typography>
						</Grid>
						<Grid item xs={6}>
							<Stack justifyContent="center" alignItems="flex-start" height="100%">
								<Typography color={themeObj.subtitle} fontSize="0.8rem">Quantity Sold</Typography>
								<Typography color={themeObj.title} fontSize="0.8rem" fontWeight={600}>{props.total_quantity}</Typography>
							</Stack>
						</Grid>
						<Grid item xs={6}>
							<Stack justifyContent="center" alignItems="flex-start" height="100%">
								<Typography color={themeObj.subtitle} fontSize="0.8rem">Total Revenue</Typography>
								<Typography color={themeObj.title} fontSize="0.8rem" fontWeight={600}>{props.total_sales}</Typography>
							</Stack>
						</Grid>
						<Grid item xs={6}>
							<Stack justifyContent="center" alignItems="flex-start" height="100%">
								<Typography color={themeObj.subtitle} fontSize="0.8rem">Transaction Count</Typography>
								<Typography color={themeObj.title} fontSize="0.8rem" fontWeight={600}>{props.transaction_count}</Typography>
							</Stack>
						</Grid>
						<Grid item xs={6}>
							<Stack justifyContent="center" alignItems="flex-start" height="100%">
								<Typography color={themeObj.subtitle} fontSize="0.8rem">Qty. Per Transaction</Typography>
								<Typography color={themeObj.title} fontSize="0.8rem" fontWeight={600}>{props.average_quantity_per_transaction}</Typography>
							</Stack>
						</Grid>
					</Grid>
				</Paper>
			</Button>
		</Box>
	)
}