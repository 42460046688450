import React from "react";
import { Collapse, Typography, Box } from "@mui/material";
import InventoryItem from "../../components/Inventory/InventoryItem";
import { TransitionGroup } from "react-transition-group";
import InventoryItemLoader from "../../components/Inventory/InventoryItemLoader";
import Pagination from "../../components/Common/Pagination";
import StatisticDisplay from "../../components/Inventory/StatisticDisplay";
import ItemAutocomplete from "../../components/Common/ItemAutocomplete";
import { useTheme } from "../../context/ThemeContext";
import { useNavigate } from "react-router-dom";

export default function InventorySearch({ loaded, page, setPage, renderedData, doSearch, risks, setRisks, searchCallback, hasNext, statistics }) {
	const { themeObj } = useTheme();
	const navigate = useNavigate();

	return (
		<>
			<ItemAutocomplete onResult={searchCallback} />
			<Box height={5}></Box>
			<StatisticDisplay
				action={risks}
				setAction={(risk) => { setRisks(risk); doSearch(risk) }}
				statistics={statistics}
				loaded={loaded}
			/>
			<TransitionGroup>
				{loaded
					? renderedData?.length !== 0 &&
					renderedData?.map((e) => (
						<Collapse key={e.item_id}>
							<InventoryItem
								{...e}
								risk={e.risk}
								onClick={() => navigate(`/inventory/${e.item_id}`)}
							/>
						</Collapse>
					))
					: Array.from({ length: 20 }, (e, i) => (
						<Collapse key={i}>
							<InventoryItemLoader />
						</Collapse>
					))
				}
			</TransitionGroup>
			{loaded && renderedData.length === 0 && (
				<Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: "center", justifyContent: "center", width: "100%", my: 5 }}>
					<Typography variant="h5" sx={{ color: themeObj.title }}>No Data <span style={{ color: themeObj.primary, fontWeight: 600 }}>!</span></Typography>
				</Box>
			)}
			{renderedData.length !== 0 &&
				<Box
					sx={{
						width: "100%",
						display: "flex",
						justifyContent: "center",
						marginBottom: "10px",
					}}
				>
					<Pagination page={page} setPage={setPage} hasNext={hasNext} />
				</Box>
			}
		</>
	);
}