import React, { useEffect, useRef, useState } from "react";
import { Button, Stack, Typography } from '@mui/material'
import ItemsTable from "../components/Purchase/ItemsTable";
import { useTheme } from "../context/ThemeContext";
import { toast } from "react-toastify";
import BarcodeScanner from "../components/Common/BarcodeScanner";
import ItemAutocomplete from "../components/Common/ItemAutocomplete";
import PaymentDetails from "../components/Purchase/PaymentDetails";
import TransactionDetails from "../components/Purchase/TransactionDetails";
import { useAuth } from "../context/AuthContext";
import VendorDetails from "../components/Purchase/VendorDetails";
import { createPurchase } from "../apis/transactionApi";
import { useLocation } from "react-router-dom";

export default function PurchasePage() {
	const itemsTableRef = useRef(null);
	const [amount, setAmount] = useState({});
	const { themeObj } = useTheme();
	const paymentDetails = useRef({});
	const transactionDetails = useRef({});
	const barcodeRef = useRef(null);
	const vendorDetails = useRef({});
	const formRefs = useRef({ vendor: null, payment: null, transaction: null });
	const { mongoUser, store } = useAuth();
	const location = useLocation();

	const addItem = (data) => {
		itemsTableRef.current.addItem(data);
	}

	const handleRefUpdate = (formName, ref) => {
		formRefs.current[formName] = ref;
	};

	const onFormSubmit = (form, values) => {
		switch (form) {
			case "payment":
				paymentDetails.current = values;
				break;
			case "vendor":
				vendorDetails.current = values;
				break;
			default:
				transactionDetails.current = values;
				break;
		}
	}

	const handleSubmit = async () => {
		// clearing the values
		let items = [];
		paymentDetails.current = {};
		transactionDetails.current = {};
		vendorDetails.current = {};

		if (itemsTableRef.current && itemsTableRef.current.handleSubmit) {
			items = await itemsTableRef.current.handleSubmit();
			itemsTableRef.current.hide();
		}

		Object.values(formRefs.current).forEach(async (ref) => { ref.hide(); await ref.requestSubmit() });

		setTimeout(async () => {
			var flag = false;
			var error = []

			if (items.length === 0) {
				itemsTableRef.current.show();
				error = "Item List cannot be empty.";
				flag = true;
			}
			else if (!items.every(i => i)) {
				itemsTableRef.current.show();
				error = "Some items details are missing.";
				flag = true;
			}
			if (flag) {
				console.log("some error");
				toast.error(error);
				return;
			}

			// do transaction code here ig, after the tests
			const transactionsObj = {
				store_id: store.store_id,
				transaction_type: "purchase",
				...transactionDetails.current,
				items,
				payments: { ...paymentDetails.current },
				seller_details: {
					uid: mongoUser._id,
					store_gstin: store.store_gstin,
					store_name: store.store_name,
					name: mongoUser.name,
					phone: mongoUser.phone,
					email: mongoUser.email,
				},
				...amount,
				total_amount: amount.total_amount + amount.charges_amount,
				vendor_details: { ...vendorDetails.current },
				status: paymentDetails.current.status
			}

			console.log(transactionsObj);

			const response = await createPurchase(transactionsObj);
			if (!response.success) {
				toast.error(response.message);
				return;
			}
			toast.success("Transaction successful");
		}, 500) // add delay, or else it doesnt reflect value for some reason aaaaaa
	}

	useEffect(() => {
		if (location.state) {
			if (location.state.from === "vendors") {
				formRefs.current.vendor.setValues(location.state.data.vendor);
				location.state.data.items.forEach(item => {
					addItem({ type: "REDIRECT", value: item });
				});
			}
		}
	}, []);

	return (
		<Stack gap={0.5}>
			<BarcodeScanner ref={barcodeRef} onResult={addItem} />
			<ItemAutocomplete onResult={addItem} />
			<ItemsTable amount={amount} onAmountChange={(amount) => setAmount(amount)} ref={itemsTableRef} />
			<VendorDetails ref={(ref) => handleRefUpdate("vendor", ref)} onFormSubmit={onFormSubmit} />
			<PaymentDetails updateCharges={(val) => setAmount(prev => { return { ...prev, charges_amount: val } })} amount={amount} ref={(ref) => handleRefUpdate("payment", ref)} onFormSubmit={onFormSubmit} />
			<TransactionDetails amount={amount} ref={(ref) => handleRefUpdate("transaction", ref)} onFormSubmit={onFormSubmit} />
			<Button
				onClick={handleSubmit}
				sx={{
					marginBottom: "10px",
					backgroundColor: themeObj.primary,
					color: themeObj.title,
					'&:hover': {
						backgroundColor: themeObj.primary_hover,
					}
				}}
			>
				Do transaction
			</Button>
		</Stack>
	)
}