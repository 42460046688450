const BASE_URL = process.env.REACT_APP_BACKEND_URL;
const AUTH_TOKEN = "Bearer 22335564899";

async function getStockStatistics() {
	const url = `${BASE_URL}/stocks/view_stock_statistics`;
	try {
		const response = await fetch(url, {
			headers: { Authorization: AUTH_TOKEN },
		});
		if (!response.ok) {
			return { success: false, message: `Failed to search items: ${response.statusText}` };
		}
		const data = await response.json();
		return { success: true, data };
	} catch (error) {
		return { success: false, message: "Network error, unable to search items." };
	}
}

async function getItemStatistics(itemId, lastNDays = 200) {
	const url = `${BASE_URL}/items/view_item_statistics?item_id=${itemId}&last_n_days=${lastNDays}`;
	try {
		const response = await fetch(url, {
			headers: { Authorization: AUTH_TOKEN },
		});
		if (!response.ok) {
			return { success: false, message: `Failed to fetch item statistics: ${response.statusText}` };
		}
		const data = await response.json();
		return { success: true, data };
	} catch (error) {
		return { success: false, message: "Network error, unable to fetch item statistics." };
	}
}

async function getHomeStatistics() {
	const url = `${BASE_URL}/home/home_insight`;
	try {
		const response = await fetch(url, {
			headers: { Authorization: AUTH_TOKEN },
		});
		if (!response.ok) {
			return { success: false, message: `Failed to fetch home statistics: ${response.statusText}` };
		}
		const data = await response.json();
		return { success: true, data };
	}
	catch (error) {
		return { success: false, message: "Network error, unable to fetch home statistics." };
	}
}

export { getStockStatistics, getItemStatistics, getHomeStatistics };