import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { Button, Paper, IconButton, TextField, CircularProgress, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Player } from "@lordicon/react";
import { useTheme } from "../../context/ThemeContext";
import useAudioRecorder from '../../hooks/useAudioRecorder';
import { searchItemsTypesense } from '../../apis/typesenseApi';
import { searchAudio } from "../../apis/audioApi";
import { SearchRounded } from "@mui/icons-material";

const MICROPHONE = require('../../data/microphone.json');
const langaugeApi = require('../../apis/languageApi');

let typingTimeout;

const ItemAutocomplete = forwardRef(({ onResult, icon }, ref) => {
	const [options, setOptions] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const { isRecording, startRecording, stopRecording, recordingBlob } = useAudioRecorder();
	const [state, setState] = useState('in-reveal');
	const { themeObj } = useTheme();
	const [disabled, setDisabled] = useState(false);

	const playerRef = useRef(null);

	useImperativeHandle(ref, () => ({
		enable: () => setDisabled(false),
		disable: () => setDisabled(true),
		clear: () => setInputValue('')
	}));

	useEffect(() => {
		playerRef.current?.playFromBeginning();
		setLoaded(true);
	}, []);

	useEffect(() => {
		if (state === 'in-reveal' && loaded) {
			setState('loop-recording');
			playerRef.current?.playFromBeginning();
		}
		if (state === 'loop-recording' && loaded) {
			setState('in-reveal');
			playerRef.current?.play();
		}
	}, [isRecording]);

	useEffect(() => {
		if (recordingBlob) {
			const reader = new FileReader();
			reader.readAsDataURL(recordingBlob);
			reader.onloadend = async function () {
				let base64String = reader.result;
				const response = await searchAudio(base64String.slice(base64String.indexOf(',') + 1));
				if (response.success) {
					console.log(response.data.data.replace(/[.,/#!$%^&*;:{}=_`~()]/g, ""))
					setInputValue(response.data.data.replace(/[.,/#!$%^&*;:{}=_`~()]/g, ""));
					handleInputChange(null, response.data.data.replace(/[.,/#!$%^&*;:{}=_`~()]/g, ""));
					setOpen(true);
				}
				else {
					console.error("Audio search error:", response.message);
					setOpen(false);
				}

				// langaugeApi.synthesizeAudioTranslate(base64String.slice(base64String.indexOf(',') + 1), 'en', 'en')
				// 	.then(response => {
				// 		response.final_output?.replace(/[.,/#!$%^&*;:{}=_`~()]/g, "");
				// 		setInputValue(response.final_output);
				// 		handleInputChange(null, response.final_output);
				// 		setOpen(true);
				// 	})
				// 	.catch(err => {
				// 		console.error("Audio translation error:", err);
				// 	});
			}
		}
	}, [recordingBlob]);

	const handleInputChange = (event, newInputValue) => {
		setInputValue(newInputValue);
		setLoading(true);
		console.log("Input value:", newInputValue);
		clearTimeout(typingTimeout);
		typingTimeout = setTimeout(async () => {
			if (newInputValue) {
				try {
					const searchResults = await searchItemsTypesense({ query: newInputValue });
					console.log(searchResults);
					setOptions(searchResults.items);
				} catch (error) {
					console.error("Search error:", error);
					setOptions([]);
				}
			} else {
				setOptions([]);
			}
			setLoading(false);
			setOpen(true);
		}, 500);
	};

	return (
		<Paper
			elevation={3}
			sx={{
				display: "flex",
				borderRadius: "5px",
				padding: "5px",
				justifyContent: "center",
				alignItems: "center",
				marginBottom: "5px",
				background: themeObj.card,
				color: themeObj.title,
				gap: 1.2
			}}
		>
			<Autocomplete
				freeSolo
				fullWidth
				size="small"
				disabled={disabled}
				options={options}
				getOptionLabel={(option) => option.item_name || option}
				isOptionEqualToValue={(option, value) => option.item_id === value.item_id}
				inputValue={inputValue}
				onInputChange={handleInputChange}
				onChange={(event, newValue) => {
					if (newValue && typeof newValue !== 'string') {
						onResult({ type: 'TYPESENSE', value: newValue });
					}
				}}
				onKeyDown={(event) => {
					if (event.key === 'Enter') {
						onResult({ type: 'SEARCH', value: inputValue });
					}
				}}
				open={open}
				onOpen={() => setOpen(true)}
				onClose={() => setOpen(false)}
				loading={loading}
				slotProps={{
					popper: {
						sx: { backgroundColor: themeObj.secondary, color: themeObj.title }
					},
					paper: {
						sx: { backgroundColor: themeObj.secondary, color: themeObj.title }
					},
					listbox: {
						sx: { color: themeObj.title }
					},
					clearIndicator: {
						sx: { color: themeObj.primary }
					}
				}}
				renderOption={(props, option) => {
					const { key, ...otherProps } = props;
					return (
						<li key={key} {...otherProps}>
							<div style={{ width: '100%' }}>
								<Typography variant="body1" style={{ color: themeObj.title }}>
									{option.item_name}
								</Typography>
							</div>
						</li>
					);
				}}
				sx={{
					'& .MuiAutocomplete-loading': {
						color: themeObj.title,
					}
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder="Enter Items"
						fullWidth
						sx={{
							'& .MuiOutlinedInput-root': {
								'&.Mui-focused fieldset': {
									border: "none",
								},
								'& fieldset': {
									border: "none",
								},
							}
						}}
						InputLabelProps={{
							sx: {
								color: themeObj.title,
								fontSize: '0.8rem',
								'&.Mui-focused': {
									color: themeObj.primary
								},
							}
						}}
						InputProps={{
							...params.InputProps,
							sx: {
								color: themeObj.title,
							},
						}}
					/>
				)}
			/>
			<IconButton
				disabled={disabled}
				onClick={() => onResult({ type: 'SEARCH', value: inputValue })}
			>
				{icon ? icon : <SearchRounded fontSize="large" htmlColor={themeObj.primary} />}
			</IconButton>
			<Button
				onClick={isRecording ? stopRecording : startRecording}
				disabled={disabled}
				sx={{
					p: 0,
					pr: 1,
					color: "black",
					borderRadius: '50%',
					minWidth: "unset",
					width: 'min-content'
				}}
			>
				<Player
					ref={playerRef}
					icon={MICROPHONE}
					state={state}
					size={35}
					colorize={themeObj.primary}
					onComplete={() => {
						if (state === 'loop-recording') playerRef.current?.playFromBeginning()
					}}
				/>
			</Button>
		</Paper>
	);
});

export default ItemAutocomplete;