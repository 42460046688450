import React from "react";
import { Box, Paper, Typography, Stack, Grid, Button } from "@mui/material";
import { useTheme } from "../../context/ThemeContext";

export default function VendorItem(props) {
	const { themeObj } = useTheme();

	return (
		<Box sx={{ marginBottom: '10px' }}>
			<Button variant="text" sx={{ color: 'revert', textTransform: 'none', width: '100%', padding: 0, borderRadius: '5px', border: 'none' }} onClick={props.onClick}>
				<Paper elevation={3} sx={{ width: '100%', padding: '10px 20px', backgroundColor: themeObj.secondary }}>
					<Stack flexDirection="column" justifyContent="space-between" alignItems="flex-start">
						<Typography sx={{ overflow: "hidden", maxHeight: "56px", textWrap: "wrap", textOverflow: "ellipsis" }} width='calc(100% - 10px)' textAlign="left" fontSize="1.2rem" color={themeObj.title}>{props.name}</Typography>
						<Typography fontSize="0.8rem" color={themeObj.subtitle}>Ph: {props.phone}</Typography>
					</Stack>
				</Paper>
			</Button>
		</Box>
	)
}