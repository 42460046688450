import { Stack, Typography, Box, IconButton, TextField } from "@mui/material";
import React from "react";
import { useTheme } from "../../context/ThemeContext";
import { Close, Edit, Save } from "@mui/icons-material";

export default function TopSection({ profilePic, storePic, name, email, isEditing, setIsEditing, submit, formik }) {
	const { themeObj } = useTheme();
	return (
		<Stack alignItems="center" sx={{ position: "relative" }}>
			<img src={storePic} alt="Store" height={150} style={{ width: "100vw", border: "1px solid", objectFit: "cover", boxShadow: `0 0 100px -40px ${themeObj.primary}` }} />
			<div style={{ position: "absolute", top: "100px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
				<img src={profilePic} alt="Profile" height={100} width={100} style={{ borderRadius: "50%", border: "2px solid", marginBottom: "10px", borderColor: themeObj.secondary }} />
			</div>
			<Box sx={{ height: "60px", width: "100%", display: "flex", justifyContent: "flex-end" }}>
				{isEditing ?
					<Stack sx={{ height: "100%" }} spacing={0.5} direction="row" alignItems="center" justifyContent="center">
						<IconButton onClick={submit}>
							<Save sx={{ fontSize: "1.75rem" }} color="success" />
						</IconButton>
						<IconButton onClick={() => setIsEditing(false)}>
							<Close sx={{ fontSize: "1.75rem" }} color="error" />
						</IconButton>
					</Stack>
					:
					<IconButton onClick={() => setIsEditing(true)}>
						<Edit color="warning" sx={{ fontSize: "1.75rem" }} />
					</IconButton>
				}
			</Box>
			{isEditing ?
				<TextField
					size="small"
					variant="standard"
					name="name"
					placeholder="Name"
					value={formik.values.name}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.name && Boolean(formik.errors.name)}
					helperText={formik.touched.name && formik.errors.name}
					sx={{ textAlign: "center" }}
					InputProps={{
						sx: {
							textAlign: "center",
							fontSize: '1.8rem',
							py: 0,
							color: themeObj.contrast,
							'&::before': {
								borderBottomColor: themeObj.subtitle
							},
							'&::after': {
								borderBottomColor: themeObj.primary
							},
							'& input': {
								py: 0
							}
						},
					}}
				/>
				:
				<Typography color={themeObj.title} fontWeight={600} fontSize="1.8rem">{name}</Typography>
			}
			<Typography color={themeObj.subtitle} fontSize="1rem">{email}</Typography>
		</Stack>
	);
}