import { Paper, Typography, TextField } from "@mui/material";
import React from "react";
import { useTheme } from "../../context/ThemeContext";

export default function VendorDetailEdit(props) {
	const { themeObj } = useTheme();
	return (
		<Paper sx={{ width: "100%", padding: "10px 15px", backgroundColor: themeObj.card }}>
			<Typography color={themeObj.subtitle} fontWeight={600} fontSize="0.7rem">{props.heading}:</Typography>
			<TextField
				size="small"
				variant="standard"
				fullWidth
				{...props}
				InputProps={{
					sx: {
						fontSize: '1rem',
						py: 0,
						color: themeObj.contrast,
						'&::before': {
							borderBottomColor: themeObj.subtitle
						},
						'&::after': {
							borderBottomColor: themeObj.primary
						},
						'& input': {
							py: 0
						}
					},
				}}
			/>
		</Paper>
	);
}