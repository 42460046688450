import React, { useState } from "react";
import { Collapse, Typography, Box, Stack } from "@mui/material";
import { TransitionGroup } from "react-transition-group";
import InventoryItemLoader from "../../components/Inventory/InventoryItemLoader";
import Pagination from "../../components/Common/Pagination";
import { useTheme } from "../../context/ThemeContext";
import { useNavigate } from "react-router-dom";
import VendorItem from "../../components/Vendor/VendorItem";
import SearchBar from "../../components/Common/SearchBar";

export default function VendorSearch({ loaded, page, setPage, renderedData, hasNext }) {
	const { themeObj } = useTheme();
	const navigate = useNavigate();
	const [search, setSearch] = useState('');

	return (
		<Stack direction="column" spacing={1}>
			<SearchBar searchString={search} setSearchString={setSearch} placeholder="Vendors" doApiSearch={() => { }} voiceSearch={() => { }} />
			<TransitionGroup>
				{loaded
					? renderedData?.length !== 0 &&
					renderedData?.map((e) => (
						<Collapse key={e.vendor_id}>
							<VendorItem
								{...e}
								onClick={() => navigate(`/vendors/${e.vendor_id}`)}
							/>
						</Collapse>
					))
					: Array.from({ length: 20 }, (e, i) => (
						<Collapse key={i}>
							<InventoryItemLoader />
						</Collapse>
					))
				}
			</TransitionGroup>
			{loaded && renderedData.length === 0 && (
				<Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: "center", justifyContent: "center", width: "100%", my: 5 }}>
					<Typography variant="h5" sx={{ color: themeObj.title }}>No Data <span style={{ color: themeObj.primary, fontWeight: 600 }}>!</span></Typography>
				</Box>
			)}
			{renderedData.length !== 0 &&
				<Box
					sx={{
						width: "100%",
						display: "flex",
						justifyContent: "center",
						marginBottom: "10px",
					}}
				>
					<Pagination page={page} setPage={setPage} hasNext={hasNext} />
				</Box>
			}
			<Box sx={{ height: 56 }} />
		</Stack>
	);
}