import React, { useState, useEffect } from "react";
import { useLocale } from "../../context/LocaleContext";
import { useRedirection } from "../../context/RedirectionContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import InventorySearch from "./InventorySearch";
import { viewInventory } from "../../apis/inventoryApi";
import ItemDetails from "./ItemDetails";
import { searchItems } from "../../apis/itemApi";
import { getStockStatistics } from "../../apis/statisticsApi";

const PAGESIZE = 30;

export default function InventoryPage() {
	const { redirectionData, setRedirection } = useRedirection();
	const { messages } = useLocale();
	const [risks, setRisks] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [renderedData, setRenderedData] = useState([]);
	const [search, setSearch] = useState("");
	const [category, setCategory] = useState("");
	const [item, setItem] = useState({});
	const [inventory, setInventory] = useState([]);
	const [page, setPage] = useState(1);
	const [hasNext, setHasNext] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();
	const [state, setState] = useState('search'); // 'search' or 'item'
	const [statistics, setStatistics] = useState({});
	const { itemId } = useParams();

	const searchCallback = async (val) => {
		if (val.type === 'TYPESENSE') {
			setItem(val.value);
			setState('item');
		}
		else {
			const response = await searchItems({ query: val.value });
			if (response.success) {
				console.log(response.data.data);
				setRenderedData(response.data.data.items.map(x => ({
					...x,
					risk: x.quantity < x.understock_qt ? 'understock' : x.quantity > x.overstock_qt ? 'overstock' : 'ok'
				})));
				setHasNext(false);
				setState('search');
			} else {
				console.error(response.message);
			}
		}
	}

	const syncData = (id, values) => {
		const val = { item_id: id, ...values, risk: values.quantity < values.understock_qt ? 'understock' : values.quantity > values.overstock_qt ? 'overstock' : 'ok' }
		setRenderedData((prev) => prev.map(item => item.item_id === id ? val : item));
		setItem(val);
	}

	const doSearch = (risk) => {
		setPage(1);
		const getData = async () => {
			try {
				const options = {
					page: 1,
					pageSize: PAGESIZE,
					itemCategory: category,
					understock: risk?.includes('understock') || undefined,
					overstock: risk?.includes('overstock') || undefined
				};

				const response = await viewInventory(options);

				if (response.success) {
					const data = response.data.data.map(x => ({
						...x,
						risk: x.quantity < x.understock_qt ? 'understock' : x.quantity > x.overstock_qt ? 'overstock' : 'ok'
					}));
					setInventory(data);
					setRenderedData(data);
					// setHasNext(response.data.page.has_next);
				} else {
					console.error(response.message);
					setHasNext(false);
				}
			} catch (e) {
				console.log(e);
				setInventory([]);
				setRenderedData([]);
			}
		};
		getData();
		setRedirection({});
	}

	const pageSearch = (page) => {
		console.log(search);
		const getData = async () => {
			try {
				const options = {
					page: page,
					pageSize: PAGESIZE,
				};

				const response = await viewInventory(options);

				if (response.success) {
					const data = response.data.data.map(x => ({
						...x,
						risk: x.quantity < x.understock_qt ? 'understock' : x.quantity > x.overstock_qt ? 'overstock' : 'ok'
					}));
					setInventory(data);
					setRenderedData(data);
					// setHasNext(response.data.page.has_next);
				} else {
					console.error(response.message);
					setHasNext(false);
				}
			} catch (e) {
				console.log(e);
				setInventory([]);
				setRenderedData([]);
			}
			setLoaded(true);
		};
		getData();
		setRedirection({});
		window.scrollTo(0, 0);
	}

	// const handlePageChange = (event, value) => {
	// 	setPage(value);
	// 	doApiSearch(risks, value);
	// };

	useEffect(() => {
		setLoaded(false);
		const getData = async () => {
			try {
				const options = {
					page: 1,
					pageSize: PAGESIZE,
				};

				const response = await viewInventory(options);

				if (response.success) {
					const data = response.data.data.map(x => ({
						...x,
						risk: x.quantity < x.understock_qt ? 'understock' : x.quantity > x.overstock_qt ? 'overstock' : 'ok'
					}));
					setInventory(data);
					setRenderedData(data);
					setHasNext(response.data.pagination.has_next);
				} else {
					console.error(response.message);
					setHasNext(false);
				}

				const statistics = await getStockStatistics();
				if (statistics.success) {
					setStatistics(statistics.data.data);
				} else {
					console.error(statistics.message);
				}
			} catch (e) {
				console.log(e);
				setInventory([]);
				setRenderedData([]);
			}
			setLoaded(true);
		};

		console.log(redirectionData);
		if (redirectionData && redirectionData.path === "/inventory") {
			setRisks(redirectionData.data.filters[0]);
			doSearch(redirectionData.data.filters || []);
		} else {
			console.log('here');
			getData();
		}
	}, []);

	useEffect(() => {
		if (redirectionData && redirectionData.path === "/inventory" && loaded) {
			console.log(redirectionData.data.filters);
			setRisks(redirectionData.data.filters[0]);
			doSearch(redirectionData.data.filters || []);
		}
	}, [redirectionData, loaded])

	useEffect(() => {
		if (loaded) {
			setLoaded(false);
			pageSearch(page)
		}
	}, [page]);

	useEffect(() => {
		if (itemId && loaded) {
			setState('item');
			let item = renderedData.find(x => x.item_id === itemId);
			if (item) {
				setItem(item);
			}
			else {
				viewInventory({ itemId })
					.then((response) => {
						if (response.success) {
							setItem(response.data.data[0]);
							setState('item');
						} else {
							console.error(response.message);
						}
					})
			}
		}
		else {
			setState('search');
			setItem({});
		}
	}, [itemId, loaded]);

	return state === 'search' ?
		<InventorySearch
			loaded={loaded}
			page={page}
			setPage={setPage}
			renderedData={renderedData}
			doSearch={doSearch}
			risks={risks}
			setRisks={setRisks}
			searchCallback={searchCallback}
			hasNext={hasNext}
			statistics={statistics}
		/>
		:
		<ItemDetails
			data={item}
			editCallback={(values) => syncData(item.item_id, values)}
		/>
		;
}