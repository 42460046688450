import { Box, Button, Collapse, Divider, Grid, IconButton, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "../../context/ThemeContext";
import { ExpandMoreRounded } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object({
	recieved: Yup.number().typeError('Recieved must be a number'),
	returned: Yup.number().typeError('Returned must be a number'),
});

export default function TransactionItem({ data }) {
	const { themeObj } = useTheme();
	const [expanded, setExpanded] = useState(false);
	const formik = useFormik({
		initialValues: {
			recieved: 0,
			returned: 0
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			console.log({
				recieved: Number(values.recieved),
				returned: Number(values.returned)
			});
		}
	})

	return (
		<Box>
			<Paper
				sx={{
					width: "100%",
					padding: "10px",
					backgroundColor: themeObj.secondary,
					display: "flex",
					flexDirection: "column",
					border: "none",
					"&:hover": {
						backgroundColor: themeObj.secondary,
					},
					borderRadius: "4px",
					borderBottomRightRadius: expanded ? "0px" : "4px",
					borderBottomLeftRadius: expanded ? "0px" : "4px",
					transition: 'all 0.2s',
					transitionDelay: !expanded ? "0.25s" : "0s"
				}}
				onClick={() => setExpanded(!expanded)}
			>
				<Stack>
					<Grid container alignItems='center'>
						<Grid item xs={10.5}>
							<Stack gap={2} flexDirection="row" alignItems="center" justifyContent="space-between">
								<Typography noWrap sx={{ overflow: "hidden", textOverflow: "ellipses" }} textAlign="left" color={themeObj.title} fontSize="1.1rem" >{data.item_name}</Typography>
								<Stack alignItems="flex-end">
									<Typography noWrap color={themeObj.title} fontSize="0.9rem">x 4323</Typography>
								</Stack>
							</Stack>
						</Grid>
						<Grid item xs={1.5}>
							<IconButton>
								<ExpandMoreRounded sx={{ transition: 'all 0.3s ease', transform: expanded ? 'rotate(-180deg)' : 'rotate(0deg)' }} htmlColor={themeObj.primary} />
							</IconButton>
						</Grid>
					</Grid>
				</Stack>
			</Paper>
			{expanded && <Divider sx={{ borderColor: themeObj.subtitle }} />}
			<Collapse in={expanded} sx={{ width: "100%" }}>
				<Box sx={{ width: "100%", padding: "10px 15px", backgroundColor: themeObj.secondary, borderBottomRightRadius: "4px", borderBottomLeftRadius: "4px" }}>
					<form
						component="form"
						onSubmit={formik.handleSubmit}
					>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<Stack>
									<Typography color={themeObj.subtitle} fontWeight={600} fontSize="0.9rem">Quantity Placed</Typography>
									<Typography color={themeObj.title} fontSize="1rem">{data.quantity || 0}</Typography>
								</Stack>
							</Grid>
							<Grid item xs={6}>
								<Stack>
									<Typography color={themeObj.subtitle} fontWeight={600} fontSize="0.9rem">Total Received</Typography>
									<Typography color={themeObj.title} fontSize="1rem">{data.quantity || 0}</Typography>
								</Stack>
							</Grid>
							<Grid item xs={6}>
								<Stack>
									<Typography color={themeObj.subtitle} fontWeight={600} fontSize="0.9rem">Quantity Received</Typography>
									<TextField
										size="small"
										variant="standard"
										name="recieved"
										placeholder="Recieved"
										value={formik.values.recieved}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.recieved && Boolean(formik.errors.recieved)}
										helperText={formik.touched.recieved && formik.errors.recieved}
										InputProps={{
											sx: {
												fontSize: '1rem',
												py: 0,
												color: themeObj.contrast,
												'&::before': {
													borderBottomColor: themeObj.subtitle
												},
												'&::after': {
													borderBottomColor: themeObj.primary
												},
												'& input': {
													py: 0
												}
											},
										}}
									/>
								</Stack>
							</Grid>
							<Grid item xs={6}>
								<Stack>
									<Typography color={themeObj.subtitle} fontWeight={600} fontSize="0.9rem">Total Returned</Typography>
									<Typography color={themeObj.title} fontSize="1rem">{data.quantity || 0}</Typography>
								</Stack>
							</Grid>
							<Grid item xs={6}>
								<Stack>
									<Typography color={themeObj.subtitle} fontWeight={600} fontSize="0.9rem">Quantity Returned</Typography>
									<TextField
										size="small"
										variant="standard"
										name="returned"
										placeholder="Returned"
										value={formik.values.returned}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.returned && Boolean(formik.errors.returned)}
										helperText={formik.touched.returned && formik.errors.returned}
										InputProps={{
											sx: {
												fontSize: '1rem',
												py: 0,
												color: themeObj.contrast,
												'&::before': {
													borderBottomColor: themeObj.subtitle
												},
												'&::after': {
													borderBottomColor: themeObj.primary
												},
												'& input': {
													py: 0
												}
											},
										}}
									/>
								</Stack>
							</Grid>
							<Grid item xs={12}>
								<Button type="submit" variant="contained" fullWidth color="warning">Update</Button>
							</Grid>
						</Grid>
					</form>
				</Box>
			</Collapse>
		</Box>
	);
}