import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { Box, Typography, Stack, Divider, IconButton, Grid, Button, MenuItem, Select, InputAdornment, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import CustomButton from "../Base/CustomButton";
import { useTheme } from "../../context/ThemeContext";
import { ArrowUpwardRounded, ClearAllRounded, ClearRounded, X } from "@mui/icons-material";
import DateFilter from "./DateFilter";
import { useLocale } from "../../context/LocaleContext";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'

const drawerBleeding = 70;

const Puller = styled('div')(({ theme }) => ({
	width: 30,
	height: 6,
	borderRadius: 3,
	position: 'absolute',
	top: 12,
	left: 'calc(50% - 15px)',
}));

const Filters = forwardRef(({ applyCallback }, ref) => {
	const [open, setOpen] = useState(false);
	const [start, setStart] = useState(null);
	const [end, setEnd] = useState(null);
	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('');
	const [minAmount, setMinAmount] = useState('');
	const [maxAmount, setMaxAmount] = useState('');
	const [category, setCategory] = useState('');
	const [paymentMethod, setPaymentMethod] = useState('');

	const { themeObj } = useTheme();
	const { messages } = useLocale();

	const toggleDrawer = (newOpen) => () => {
		setOpen(newOpen);
	};

	const toggleOrder = () => {
		if (order === 'asc') {
			setOrder('desc');
		} else {
			setOrder('asc');
		}
	}

	const reset = () => {
		setStart(null);
		setEnd(null);
		setOrder('asc');
		setOrderBy('');
		setMinAmount('');
		setMaxAmount('');
		setCategory('');
		setPaymentMethod('');
	}

	useImperativeHandle(ref, () => ({
		getValues: () => ({ start: dayjs.utc(start).format(), end: dayjs.utc(end).format(), order, orderBy, minAmount, maxAmount, category, paymentMethod }),
		reset: () => reset()
	}), [start, end, order, orderBy, minAmount, maxAmount, category, paymentMethod]);

	return (
		<>
			<SwipeableDrawer
				anchor="bottom"
				open={open}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
				swipeAreaWidth={drawerBleeding}
				disableSwipeToOpen={false}
				ModalProps={{
					keepMounted: true,
				}}
				sx={{
					'& .MuiDrawer-paper': {
						height: `calc(fit-content - ${drawerBleeding}px)`,
						overflow: 'visible',
					},
				}}
			>
				<Box
					sx={{
						backgroundColor: themeObj.card,
						position: 'absolute',
						top: -drawerBleeding,
						borderTopLeftRadius: 10,
						borderTopRightRadius: 10,
						visibility: 'visible',
						right: 0,
						left: 0,
						height: drawerBleeding,
						display: 'flex',
						justifyContent: 'center',
						width: "100%",
						flexDirection: "column",
					}}
				>
					<Puller sx={{ backgroundColor: themeObj.subtitle }} />
					<Stack flexDirection="row" justifyContent="space-between" alignItems="center">
						<Typography sx={{ p: 2 }} color={themeObj.title} fontSize="1.2rem">Filters</Typography>
						{open &&
							<IconButton onClick={reset}>
								<Stack sx={{ px: 2 }} flexDirection="row" gap={1} alignItems="center">
									<Typography color={themeObj.title} fontSize="0.8rem">Clear Filters</Typography>
									<ClearAllRounded fontSize="large" htmlColor={themeObj.primary} />
								</Stack>
							</IconButton>
						}
					</Stack>
				</Box>
				<Grid container sx={{ backgroundColor: themeObj.card, p: 2 }} spacing={1}>
					<Grid item xs={6}>
						<DateFilter label={messages.transaction.filters.startDate} value={start} setValue={setStart} />
					</Grid>
					<Grid item xs={6}>
						<DateFilter label={messages.transaction.filters.endDate} value={end} setValue={setEnd} />
					</Grid>
					<Grid item xs={8}>
						<TextField
							select
							fullWidth
							label="Order By"
							value={orderBy}
							onChange={(e) => setOrderBy(e.target.value)}
							sx={{
								fontSize: "0.8rem",
								color: themeObj.title,
								'& .MuiOutlinedInput-root': {
									'&.Mui-focused fieldset': {
										borderColor: themeObj.primary,
									},
									'& fieldset': {
										borderColor: themeObj.subtitle,
									},
								},
								'& .MuiSelect-icon': {
									color: themeObj.title
								}
							}}
							InputLabelProps={{
								sx: {
									fontSize: '0.8rem',
									color: themeObj.title,
									'&.Mui-focused': {
										color: themeObj.primary
									},
								}
							}}
							InputProps={{
								endAdornment: orderBy ?
									<InputAdornment sx={{ position: "absolute", right: 26 }}>
										<IconButton onClick={() => setOrderBy('')}>
											<ClearRounded htmlColor={themeObj.chip.red} />
										</IconButton>
									</InputAdornment>
									:
									null,
								sx: {
									fontSize: '0.8rem',
									color: themeObj.title
								},
							}}
							SelectProps={{
								MenuProps: {
									sx: {
										'& .MuiMenu-paper': {
											backgroundColor: themeObj.card,
										},
										'& .MuiMenu-list': {
											p: 0
										}
									}
								}
							}}
						>
							<MenuItem sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} value="date">Date</MenuItem>
						</TextField>
					</Grid>
					<Grid item xs={4} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
						<Button
							onClick={toggleOrder}
							startIcon={
								<ArrowUpwardRounded htmlColor={themeObj.primary}
									sx={{
										transform: order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
										transition: "all 300ms ease"
									}}
								/>
							}
							sx={{
								p: 1.5,
								width: "90%",
								color: themeObj.title,
							}}
						>
							{order}
						</Button>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							type="number"
							label="Min. Amount"
							value={minAmount}
							onChange={(e) => setMinAmount(e.target.value)}
							sx={{
								'& .MuiOutlinedInput-root': {
									'&.Mui-focused fieldset': {
										borderColor: themeObj.primary,
									},
									'& fieldset': {
										borderColor: themeObj.subtitle,
									},
								}
							}}
							InputLabelProps={{
								sx: {
									fontSize: '0.8rem',
									color: themeObj.title,
									'&.Mui-focused': {
										color: themeObj.primary
									},
								}
							}}
							InputProps={{
								sx: {
									fontSize: '0.8rem',
									color: themeObj.title
								},
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							type="number"
							label="Max. Amount"
							value={maxAmount}
							onChange={(e) => setMaxAmount(e.target.value)}
							sx={{
								'& .MuiOutlinedInput-root': {
									'&.Mui-focused fieldset': {
										borderColor: themeObj.primary,
									},
									'& fieldset': {
										borderColor: themeObj.subtitle,
									},
								}
							}}
							InputLabelProps={{
								sx: {
									fontSize: '0.8rem',
									color: themeObj.title,
									'&.Mui-focused': {
										color: themeObj.primary
									},
								}
							}}
							InputProps={{
								sx: {
									fontSize: '0.8rem',
									color: themeObj.title
								},
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							select
							fullWidth
							label="Item Category"
							value={category}
							onChange={(e) => setCategory(e.target.value)}
							sx={{
								fontSize: "0.8rem",
								color: themeObj.title,
								'& .MuiOutlinedInput-root': {
									'&.Mui-focused fieldset': {
										borderColor: themeObj.primary,
									},
									'& fieldset': {
										borderColor: themeObj.subtitle,
									},
								},
								'& .MuiSelect-icon': {
									color: themeObj.title
								}
							}}
							InputLabelProps={{
								sx: {
									fontSize: '0.8rem',
									color: themeObj.title,
									'&.Mui-focused': {
										color: themeObj.primary
									},
								}
							}}
							InputProps={{
								sx: {
									fontSize: '0.8rem',
									color: themeObj.title
								},
							}}
							SelectProps={{
								MenuProps: {
									sx: {
										'& .MuiMenu-paper': {
											backgroundColor: themeObj.card,
										},
										'& .MuiMenu-list': {
											p: 0
										}
									}
								}
							}}
						>
							<MenuItem sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} value="">None</MenuItem>
							<MenuItem sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} value="Electronics">Electronics</MenuItem>
						</TextField>
					</Grid>
					<Grid item xs={6}>
						<TextField
							select
							fullWidth
							label="Payment Method"
							value={paymentMethod}
							onChange={(e) => setPaymentMethod(e.target.value)}
							sx={{
								fontSize: "0.8rem",
								color: themeObj.title,
								'& .MuiOutlinedInput-root': {
									'&.Mui-focused fieldset': {
										borderColor: themeObj.primary,
									},
									'& fieldset': {
										borderColor: themeObj.subtitle,
									},
								},
								'& .MuiSelect-icon': {
									color: themeObj.title
								}
							}}
							InputLabelProps={{
								sx: {
									fontSize: '0.8rem',
									color: themeObj.title,
									'&.Mui-focused': {
										color: themeObj.primary
									},
								}
							}}
							InputProps={{
								sx: {
									fontSize: '0.8rem',
									color: themeObj.title
								},
							}}
							SelectProps={{
								MenuProps: {
									sx: {
										'& .MuiMenu-paper': {
											backgroundColor: themeObj.card,
										},
										'& .MuiMenu-list': {
											p: 0
										}
									}
								}
							}}
						>
							<MenuItem sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} value="">None</MenuItem>
							<MenuItem sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} value="online">Online</MenuItem>
							<MenuItem sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} value="cash">Cash</MenuItem>
						</TextField>
					</Grid>
					<Grid item xs={12}>
						<Button
							fullWidth
							onClick={() => {
								applyCallback({ start: dayjs.utc(start).format(), end: dayjs.utc(end).format(), order, orderBy, minAmount, maxAmount, category, paymentMethod });
								toggleDrawer(false)();
							}}
							sx={{
								marginBottom: "10px",
								backgroundColor: themeObj.primary,
								color: themeObj.title,
								py: 1,
								'&:hover': {
									backgroundColor: themeObj.primary_hover,
								}
							}}
						>
							Apply Filters
						</Button>
					</Grid>
				</Grid>
			</SwipeableDrawer>
		</>
	);
});

export default Filters;
