import { Box, Button, Collapse, Divider, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "../../context/ThemeContext";
import { KeyboardArrowUpRounded } from "@mui/icons-material";

export default function Accordian({ label, children }) {
	const { themeObj } = useTheme();
	const [expanded, setExpanded] = useState(false);

	return (
		<>
			<Button
				onClick={() => setExpanded(!expanded)}
				fullWidth
				sx={{
					textTransform: "none",
					width: "100%",
					padding: "15px",
					backgroundColor: themeObj.secondary,
					display: "flex",
					flexDirection: "column",
					border: "none",
					"&:hover": {
						backgroundColor: themeObj.secondary,
					},
					borderRadius: "4px",
					borderBottomRightRadius: expanded ? "0px" : "4px",
					borderBottomLeftRadius: expanded ? "0px" : "4px",
					transition: 'all 0.2s',
					transitionDelay: !expanded ? "0.25s" : "0s"
				}}
			>
				<Stack justifyContent="space-between" alignItems="center" direction="row" gap={1} sx={{ width: "100%" }}>
					<Typography color={themeObj.title} fontWeight={500} fontSize="1.2rem">{label}</Typography>
					<KeyboardArrowUpRounded htmlColor={themeObj.primary} sx={{ transform: expanded ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 0.25s' }} />
				</Stack>
			</Button>
			{expanded && <Divider sx={{ borderColor: themeObj.subtitle }} />}
			<Collapse in={expanded} sx={{ width: "100%" }}>
				<Box sx={{ width: "100%", padding: "10px 15px", backgroundColor: themeObj.secondary, borderBottomRightRadius: "4px", borderBottomLeftRadius: "4px" }}>
					{children}
				</Box>
			</Collapse>
		</>
	);
}