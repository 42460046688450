import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import VendorSearch from "./VendorSearch";
import data from './sample.json';
import VendorDetails from "./VendorDetails";

const PAGESIZE = 30;

export default function VendorPage() {
	const [loaded, setLoaded] = useState(false);
	const [renderedData, setRenderedData] = useState([]);
	const [vendor, setVendor] = useState({});
	const [page, setPage] = useState(1);
	const [hasNext, setHasNext] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();
	const [state, setState] = useState('search');
	const { vendorId } = useParams();

	useEffect(() => {
		if (vendorId && loaded) {
			let item = renderedData.find(x => x.vendor_id === vendorId);
			if (item) {
				setVendor(item);
				setState('vendor');
			}
		}
		else {
			setState('search');
			setVendor({});
		}
	}, [vendorId, loaded]);

	useEffect(() => {
		setRenderedData(data);
		setLoaded(true);
	}, []);

	return state === 'search' ?
		<VendorSearch
			loaded={loaded}
			page={page}
			setPage={setPage}
			renderedData={renderedData}
			hasNext={hasNext}
		/>
		:
		<VendorDetails data={vendor} editCallback={(values) => console.log(values)} />
		;
}