import { Box, Button, Chip, CircularProgress, Collapse, Grid, LinearProgress, Paper, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "../../context/ThemeContext";
import {
	AccessTimeRounded,
	TrendingDownRounded,
	TrendingUpRounded,
	BarChartRounded,
	CalendarMonthRounded,
	CategoryRounded,
	ArrowUpwardRounded,
	ArrowDownwardRounded,
	CachedRounded,
	InfoOutlined,
	ArrowDropUpRounded,
	ArrowDropDownRounded,
	TaskAltRounded,
	WarningAmberRounded,
	HorizontalRuleRounded,
	ExpandMoreRounded
} from "@mui/icons-material";

import Chart from "react-apexcharts";
import { LabelList, Line, LineChart, ResponsiveContainer, YAxis } from "recharts";
import TextWithInfo from "../Common/TextWithInfo";

const COLORS = {
	YELLOW: "#eab308",
	GREEN: "#22c55e",
	RED: "#fd1821",
	ORANGE: "#f97316",
	BLUE: "#3b82f6",
	PURPLE: "#a855f7",
	TEAL: "#008080",
	LIGHT_BLUE: "#ADD8E6",
	SKY_BLUE: "#87CEEB",
	CORNFLOWER_BLUE: "#6495ED",
	INDIGO: "#4B0082",
};

export default function ItemStats({ statsData, loaded, setDays }) {
	const { themeObj } = useTheme();
	const [open, setOpen] = useState(false);
	const { sales_performance, inventory_turnover, popularity_badge, reorder_recommendation, item_details } = statsData;
	const [timeFrame, setTimeFrame] = useState("weekly");
	const [demandTimeframe, setDemandTimeframe] = useState("weekly");

	const timeFrames = [
		{ label: "Daily", value: "daily", days: 1 },
		{ label: "Weekly", value: "weekly", days: 7 },
		{ label: "Monthly", value: "monthly", days: 30 },
		{ label: "Quarter", value: "quarterly", days: 90 },
		{ label: "Yearly", value: "yearly", days: 365 },
	];

	const scoreEmoji = (score) => {
		if (score >= 95) return `${score} 🚀🚀`;
		if (score >= 90) return `${score} 🚀`;
		if (score >= 85) return `${score} 🌟`;
		if (score >= 75) return `${score} 👍`;
		if (score >= 65) return `${score} 😊`;
		if (score >= 55) return `${score} 🙂`;
		if (score >= 45) return `${score} 😐`;
		if (score >= 35) return `${score} 😟`;
		if (score >= 25) return `${score} 😢`;
		return `${score} 😭`;
	}

	const chartData = [
		{
			period: "1",
			sales: sales_performance?.sales_data?.previous_period?.total_sales,
		},
		{
			period: "2",
			sales: sales_performance?.sales_data?.current_period?.total_sales,
		}
	]

	const reorderStatusIconMap = {
		"Reorder Soon": <AccessTimeRounded htmlColor={COLORS.YELLOW} />,
		"Stock Sufficient": <TaskAltRounded htmlColor={COLORS.GREEN} />,
		"Reorder Now": <WarningAmberRounded htmlColor={COLORS.RED} />,
	}

	const inventoryTurnoverIconMap = {
		"High": <ArrowUpwardRounded htmlColor={COLORS.GREEN} />,
		"Good": <ArrowUpwardRounded htmlColor={COLORS.TEAL} />,
		"Moderate": <ArrowUpwardRounded htmlColor={COLORS.LIGHT_BLUE} />,
		"Low": <ArrowDownwardRounded htmlColor={COLORS.RED} />,
		"No Movement": <HorizontalRuleRounded htmlColor={COLORS.YELLOW} />,
	}

	return (
		<Grid container spacing={1} sx={{ position: "relative", mt: 1.5 }}>
			{!loaded && <Grid item xs={12} sx={{ backdropFilter: 'blur(5px)', zIndex: 10, position: "absolute", top: 0, right: 0, height: '100%', width: '100%', display: "flex", justifyContent: "center" }}>
				<div style={{ height: 'fit-content', flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center", gap: 15, position: "relative", top: 100 }}>
					<CircularProgress color="warning" />
					<Typography color={themeObj.title} fontSize="1.2rem">Loading</Typography>
				</div>
			</Grid>}
			<Grid item xs={12}>
				<Box sx={{ display: "flex", flexDirection: "row", gap: 1, px: 1 }}>
					{timeFrames.map((t, index) =>
						<Chip
							key={index}
							variant={timeFrame === t.value ? "filled" : "outlined"}
							label={t.label}
							color="warning"
							size="small"
							onClick={() => { setTimeFrame(t.value); setDays(t.days) }}
						/>
					)}
				</Box>
			</Grid>
			<Grid item xs>
				<Paper sx={{ border: "1.5px solid", padding: "10px 15px", backgroundColor: themeObj.card, borderColor: themeObj.secondary, height: "100%" }}>
					<Stack flexDirection="column" alignItems="start" justifyContent="center">
						<TextWithInfo
							text="Sales Trend"
							info={sales_performance?.trend?.info}
							textProps={{ color: themeObj.subtitle, fontSize: "0.7rem" }}
							infoProps={{ htmlColor: themeObj.subtitle }}
						/>
						<Stack direction="row" gap={1} alignItems="center">
							{sales_performance?.trend?.direction === "decreasing" ?
								<>
									<TrendingDownRounded sx={{ color: themeObj.chip.red }} />
									<Typography color={themeObj.title} fontSize="1.5rem">{sales_performance?.trend?.percentage_change}% </Typography>
								</>
								:
								<>
									<TrendingUpRounded sx={{ color: themeObj.chip.green }} />
									<Typography color={themeObj.title} fontSize="1.5rem">{sales_performance?.trend?.percentage_change}% </Typography>
								</>
							}
						</Stack>
						{/* <ResponsiveContainer height={100}>
							<LineChart
								data={chartData}
								height={100}
								margin={{
									top: 5,
									bottom: 25,
									left: 12,
									right: 12,
								}}
							>
								<Line
									dataKey="sales"
									type="monotone"
									stroke={sales_performance?.trend?.direction === "decreasing" ? themeObj.chip.red : themeObj.chip.green}
									strokeWidth={2}
									activeDot={{
										r: 6,
									}}
								>
									<LabelList
										position="bottom"
										offset={12}
										fontSize={12}
									/>
								</Line>
								<YAxis domain={['dataMin', 'dataMax']} axisLine={false} tickLine={false} hide />
							</LineChart>
						</ResponsiveContainer> */}
					</Stack>
				</Paper>
			</Grid>
			<Grid item xs>
				<Paper sx={{ border: "1.5px solid", padding: "10px 15px", backgroundColor: themeObj.card, borderColor: themeObj.secondary }}>
					<Stack gap={0.65}>
						<TextWithInfo
							text="Performance Score"
							info={sales_performance?.performance_score?.info}
							textProps={{ color: themeObj.subtitle, fontSize: "0.7rem" }}
							infoProps={{ htmlColor: themeObj.subtitle }}
						/>
						<StarRating score={sales_performance?.performance_score?.value / 20} />
					</Stack>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				<Paper sx={{ border: "1.5px solid", padding: "10px 15px", backgroundColor: themeObj.card, borderColor: themeObj.secondary }}>
					<Stack>
						<Typography color={themeObj.title} fontSize="0.8rem">{sales_performance?.performance_description}</Typography>
					</Stack>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				<Paper sx={{ border: "1.5px solid", padding: "10px 15px", backgroundColor: themeObj.card, borderColor: themeObj.secondary }}>
					<Stack gap={1.5}>
						<Typography color={themeObj.title} fontSize="1.4rem">Demand Forecast</Typography>
						<Box sx={{ display: "flex", flexDirection: "row", gap: 1, px: 1 }}>
							{[{ label: "Daily", value: "daily" }, { label: "Weekly", value: "weekly" }, { label: "Monthly", value: "monthly" }].map((t, index) =>
								<Chip
									key={index}
									variant={demandTimeframe === t.value ? "filled" : "outlined"}
									label={t.label}
									color="warning"
									size="small"
									onClick={() => setDemandTimeframe(t.value)}
								/>
							)}
						</Box>
						<Typography textAlign="center" color={themeObj.title} fontSize="1.2rem">100 - 120 units</Typography>
					</Stack>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				<Paper sx={{ border: "1.5px solid", padding: "10px 15px", backgroundColor: themeObj.card, borderColor: themeObj.secondary }}>
					<Stack gap={1.5}>
						<Typography color={themeObj.title} fontSize="1.4rem">Reorder Recommendation</Typography>
						<Grid container rowSpacing={2.5} columnSpacing={0.5}>
							<Grid item xs={12}>
								<ProgressBar understock={item_details?.understock_qt} overstock={item_details?.overstock_qt} quantity={item_details?.quantity} someQty={10} />
							</Grid>
							<Grid item xs={6}>
								<Stack justifyContent="center" alignItems="center">
									<CalendarMonthRounded htmlColor={COLORS.ORANGE} />
									<TextWithInfo
										text="Days Until Understock"
										info={reorder_recommendation?.days_until_understock?.info}
										textProps={{ color: themeObj.subtitle, fontSize: "0.7rem" }}
										infoProps={{ htmlColor: themeObj.subtitle }}
									/>
									<Typography color={themeObj.title} fontSize="1rem" fontWeight={600}>{reorder_recommendation?.days_until_understock?.value || 0} days</Typography>
								</Stack>
							</Grid>
							<Grid item xs={6}>
								<Stack justifyContent="center" alignItems="center" height="100%">
									{reorderStatusIconMap[reorder_recommendation?.status?.value]}
									<TextWithInfo
										text="Status"
										info={reorder_recommendation?.status?.info}
										textProps={{ color: themeObj.subtitle, fontSize: "0.7rem" }}
										infoProps={{ htmlColor: themeObj.subtitle }}
									/>
									<Typography color={themeObj.title} fontSize="0.8rem" fontWeight={600}>{reorder_recommendation?.status?.value}</Typography>
								</Stack>
							</Grid>
							<Grid item xs={12}>
								<Typography color={themeObj.title} fontSize="0.8rem">{reorder_recommendation?.recommendation?.value}</Typography>
							</Grid>
						</Grid>
					</Stack>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				<Paper sx={{ border: "1.5px solid", padding: "10px 15px", backgroundColor: themeObj.card, borderColor: themeObj.secondary }}>
					<Stack gap={2.5}>
						<Typography color={themeObj.title} fontSize="1.4rem">Inventory Status</Typography>
						<Grid container rowSpacing={2.5} columnSpacing={0.5}>
							<Grid item xs={6}>
								<Stack justifyContent="center" alignItems="center">
									<AccessTimeRounded htmlColor={COLORS.YELLOW} />
									<TextWithInfo
										text="Average Inventory"
										info={inventory_turnover?.average_inventory?.info}
										textProps={{ color: themeObj.subtitle, fontSize: "0.7rem" }}
										infoProps={{ htmlColor: themeObj.subtitle }}
									/>
									<Typography color={themeObj.title} fontSize="1rem" fontWeight={600}>{inventory_turnover?.average_inventory?.value}</Typography>
								</Stack>
							</Grid>
							<Grid item xs={6}>
								<Stack justifyContent="center" alignItems="center" height="100%">
									{inventoryTurnoverIconMap[inventory_turnover?.status?.value]}
									<TextWithInfo
										text="Status"
										info={inventory_turnover?.status?.info}
										textProps={{ color: themeObj.subtitle, fontSize: "0.7rem" }}
										infoProps={{ htmlColor: themeObj.subtitle }}
									/>
									<Typography color={themeObj.title} fontSize="1rem" fontWeight={600}>{inventory_turnover?.status?.value}</Typography>
								</Stack>
							</Grid>
							<Grid item xs={6}>
								<Stack justifyContent="center" alignItems="center">
									<BarChartRounded htmlColor={COLORS.BLUE} />
									<TextWithInfo
										text="Turnover Rate"
										info={inventory_turnover?.turnover_rate?.info}
										textProps={{ color: themeObj.subtitle, fontSize: "0.7rem" }}
										infoProps={{ htmlColor: themeObj.subtitle }}
									/>
									<Typography color={themeObj.title} fontSize="1rem" fontWeight={600}>{inventory_turnover?.turnover_rate?.value}</Typography>
								</Stack>
							</Grid>
							<Grid item xs={6}>
								<Stack justifyContent="center" alignItems="center">
									<CachedRounded htmlColor={COLORS.GREEN} />
									<TextWithInfo
										text="Turnover Days"
										info={inventory_turnover?.turnover_days?.info}
										textProps={{ color: themeObj.subtitle, fontSize: "0.7rem" }}
										infoProps={{ htmlColor: themeObj.subtitle }}
									/>
									<Typography color={themeObj.title} fontSize="1rem" fontWeight={600}>{inventory_turnover?.turnover_days?.value}</Typography>
								</Stack>
							</Grid>
							<Grid item xs={6}>
								<Stack justifyContent="center" alignItems="center">
									<CategoryRounded htmlColor={COLORS.PURPLE} />
									<TextWithInfo
										text="Quantity Sold"
										info={inventory_turnover?.quantity_sold?.info}
										textProps={{ color: themeObj.subtitle, fontSize: "0.7rem" }}
										infoProps={{ htmlColor: themeObj.subtitle }}
									/>
									<Typography color={themeObj.title} fontSize="1rem" fontWeight={600}>{inventory_turnover?.quantity_sold?.value}</Typography>
								</Stack>
							</Grid>
							<Grid item xs={6}>
								<Stack justifyContent="center" alignItems="center">
									<CalendarMonthRounded htmlColor={COLORS.ORANGE} />
									<TextWithInfo
										text="Timeline"
										info={inventory_turnover?.period_days?.info}
										textProps={{ color: themeObj.subtitle, fontSize: "0.7rem" }}
										infoProps={{ htmlColor: themeObj.subtitle }}
									/>
									<Typography color={themeObj.title} fontSize="1rem" fontWeight={600}>{inventory_turnover?.period_days?.value}</Typography>
								</Stack>
							</Grid>
							<Grid item xs={6}>
								<Stack justifyContent="center" alignItems="center">
									<ArrowUpwardRounded htmlColor={COLORS.GREEN} />
									<TextWithInfo
										text="Beginning Inventory"
										info={inventory_turnover?.beginning_inventory?.info}
										textProps={{ color: themeObj.subtitle, fontSize: "0.7rem" }}
										infoProps={{ htmlColor: themeObj.subtitle }}
									/>
									<Typography color={themeObj.title} fontSize="1rem" fontWeight={600}>{inventory_turnover?.beginning_inventory?.value}</Typography>
								</Stack>
							</Grid>
							<Grid item xs={6}>
								<Stack justifyContent="center" alignItems="center">
									<ArrowDownwardRounded htmlColor={COLORS.RED} />
									<TextWithInfo
										text="Ending Inventory"
										info={inventory_turnover?.ending_inventory?.info}
										textProps={{ color: themeObj.subtitle, fontSize: "0.7rem" }}
										infoProps={{ htmlColor: themeObj.subtitle }}
									/>
									<Typography color={themeObj.title} fontSize="1rem" fontWeight={600}>{inventory_turnover?.ending_inventory?.value}</Typography>
								</Stack>
							</Grid>
						</Grid>
						<Typography color={themeObj.title} fontSize="0.8rem">{inventory_turnover?.description?.value}</Typography>
					</Stack>
				</Paper>
			</Grid>
		</Grid >
	);
}

const ProgressBar = ({ understock, overstock, quantity }) => {
	const max = Math.max(overstock * 1.3, quantity * 1.1);
	const progress = (quantity / max) * 100;

	const color = () => {
		if (quantity <= understock) return COLORS.RED; // Critical understock - Red
		if (quantity <= understock * 1.05) return '#FF4500'; // Severe understock - Dark Orange
		if (quantity <= understock * 1.1) return '#FFA500'; // Moderate understock - Orange
		if (quantity <= understock * 1.15) return '#FFD700'; // Approaching sufficient - Gold
		if (quantity <= understock * 1.2) return '#FFFF00'; // Sufficient stock lower limit - Yellow
		if (quantity <= understock * 1.3) return '#FFFFE0'; // Below midpoint - Light Yellow
		if (quantity <= (understock + overstock) / 2) return '#90EE90'; // Midpoint - Light Green
		if (quantity <= (understock + overstock) / 2 + (overstock - understock) * 0.1) return '#3CB371'; // Slightly above midpoint - Medium Sea Green
		if (quantity <= overstock * 0.9) return COLORS.GREEN; // Healthy stock - Green
		if (quantity <= overstock) return COLORS.TEAL; // Approaching overstock - Teal
		if (quantity <= overstock * 1.05) return COLORS.LIGHT_BLUE; // Overstock lower - Light Blue
		if (quantity <= overstock * 1.1) return COLORS.SKY_BLUE; // Overstock moderate - Sky Blue
		if (quantity <= overstock * 1.2) return COLORS.CORNFLOWER_BLUE; // Overstock high - Cornflower Blue
		return COLORS.INDIGO; // Excess stock - Indigo
	};

	const { themeObj } = useTheme();

	return (
		<Box sx={{ width: '100%', my: 4, px: 2 }}>
			<Box sx={{ width: '100%', position: 'relative' }}>
				<Box sx={{ position: 'absolute', top: -42, left: `${(quantity / max) * 100}%`, transform: 'translateX(-50%)' }}>
					<Stack justifyContent="center" alignItems="center">
						<Typography sx={{ position: "relative", top: 10 }} variant="caption" color={color()} fontSize="0.8rem">Current</Typography>
						<ArrowDropDownRounded htmlColor={themeObj.title} />
					</Stack>
				</Box>
				<LinearProgress variant="determinate" value={progress} sx={{ background: themeObj.secondary, height: 10, borderRadius: 5, '& .MuiLinearProgress-bar': { backgroundColor: color() } }} />
				<Box sx={{ position: 'absolute', top: 8, left: `${(understock / max) * 100}%`, transform: 'translateX(-50%)' }}>
					<Stack justifyContent="center" alignItems="center">
						<ArrowDropUpRounded htmlColor={themeObj.title} />
						<Typography sx={{ position: "relative", top: -10 }} variant="caption" color={COLORS.RED} fontSize="0.8rem">Understock</Typography>
					</Stack>
				</Box>
				<Box sx={{ position: 'absolute', top: 8, left: `${(overstock / max) * 100}%`, transform: 'translateX(-50%)' }}>
					<Stack justifyContent="center" alignItems="center">
						<ArrowDropUpRounded htmlColor={themeObj.title} />
						<Typography sx={{ position: "relative", top: -10 }} variant="caption" color={COLORS.GREEN} fontSize="0.8rem">Overstock</Typography>
					</Stack>
				</Box>
			</Box>
		</Box>
	);
};

const StarRating = ({ score }) => {
	const stars = Array.from({ length: 5 }, (_, index) => {
		const fillPercentage = Math.max(0, Math.min(1, score - index)) * 100; // Percentage to fill each star

		return (
			<Box
				key={index}
				sx={{
					position: "relative",
					display: "inline-block",
					marginRight: 0.5,
				}}
			>
				{/* Background (Gray Outline) */}
				<Box
					component="span"
					sx={{
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						fontSize: "1.8rem",
						lineHeight: 1,
						color: "gray", // Gray for outline
					}}
				>
					☆
				</Box>

				{/* Foreground (Gold Fill) */}
				<Box
					component="span"
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						width: `${fillPercentage}%`,
						height: "100%",
						overflow: "hidden",
						color: "gold", // Gold for filled part
						fontSize: "1.8rem",
						lineHeight: 1,
					}}
				>
					★
				</Box>
			</Box>
		);
	});

	return <Box sx={{ display: "flex" }}>{stars}</Box>;
};