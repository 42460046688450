const BASE_URL = process.env.REACT_APP_BACKEND_URL;
const AUTH_TOKEN = "Bearer 22335564899";

async function createSale(transactionData) {
	try {
		const response = await fetch(`${BASE_URL}/transactions/create_sale`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: AUTH_TOKEN,
			},
			body: JSON.stringify(transactionData),
		});
		if (!response.ok) {
			return { success: false, message: `Failed to create sale: ${response.statusText}` };
		}
		const data = await response.json();
		return { success: true, data };
	} catch (error) {
		return { success: false, message: "Network error, unable to create sale." };
	}
}

async function createPurchase(transactionData) {
	try {
		const response = await fetch(`${BASE_URL}/transactions/create_purchase`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: AUTH_TOKEN,
			},
			body: JSON.stringify(transactionData),
		});
		if (!response.ok) {
			return { success: false, message: `Failed to create purchase: ${response.statusText}` };
		}
		const data = await response.json();
		return { success: true, data };
	} catch (error) {
		return { success: false, message: "Network error, unable to create purchase." };
	}
}


async function viewTransactions(options = {}) {
	const url = `${BASE_URL}/transactions/view_transactions`;
	const params = {
		page: options.page || 1,
		page_size: options.pageSize || 30,
	};

	if (options.transactionId) params.transaction_id = options.transactionId;
	if (options.itemId) params.item_id = options.itemId;
	if (options.barcodeId) params.barcode_id = options.barcodeId;
	if (options.customerEmail) params.customer_email = options.customerEmail;
	if (options.startDate) params.start_date = options.startDate;
	if (options.endDate) params.end_date = options.endDate;
	if (options.itemCategory) params.item_category = options.itemCategory;
	if (options.type) params.transaction_type = options.type;
	if (options.paymentMethod) params.payment_method = options.paymentMethod;
	if (options.totalAmountMin) params.total_amount_min = options.totalAmountMin;
	if (options.totalAmountMax) params.total_amount_max = options.totalAmountMax;
	if (options.sortBy) params.sort_by = options.sortBy;
	if (options.sortOrder) params.sort_order = options.sortOrder;

	try {
		const response = await fetch(url + "?" + new URLSearchParams(params), {
			headers: { Authorization: AUTH_TOKEN },
		});
		if (!response.ok) {
			return { success: false, message: `Failed to view transactions: ${response.statusText}` };
		}
		const data = await response.json();
		return { success: true, data };
	} catch (error) {
		return { success: false, message: "Network error, unable to view transactions." };
	}
}

async function viewTransactionBill(transactionId) {
	const url = `${BASE_URL}/transactions/view_transaction_bill/`;
	const params = { transaction_id: transactionId }
	try {
		const response = await fetch(url + "?" + new URLSearchParams(params), {
			headers: { Authorization: AUTH_TOKEN },
		});
		if (!response.ok) {
			return { success: false, message: `Failed to fetch bill: ${response.statusText}` };
		}
		const data = await response.json();
		return { success: true, data };
	} catch (error) {
		return { success: false, message: "Network error, unable to fetch bill." };
	}
}

export { viewTransactions, createPurchase, createSale, viewTransactionBill }