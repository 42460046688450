import React, { useState, useEffect } from "react";
import { Paper, Typography, Divider, useMediaQuery, Stack, Box, Collapse } from "@mui/material";
import { useRedirection } from "../context/RedirectionContext";
import Recorder from "../components/HomePage/Recorder";
import CustomMediaPlayer from "../components/HomePage/CustomMediaPlayer";
import TransactionItem from "../components/Transaction/TransactionItem";
import TransactionItemLoader from "../components/Transaction/TransactionItemLoader";
import { TransitionGroup } from "react-transition-group";
import { Link } from "react-router-dom";
import { useLocale } from "../context/LocaleContext";
import StatisticItem from "../components/HomePage/StatisticsItem";
import { useTheme } from "../context/ThemeContext";
import { viewTransactions } from "../apis/transactionApi";
import { useAuth } from "../context/AuthContext";
import HomeStats from "../components/HomePage/HomeStats";
import { getHomeStatistics } from "../apis/statisticsApi";
const langaugeApi = require('../apis/languageApi');

export default function HomePage() {
	const { messages } = useLocale();
	const { setRedirection } = useRedirection();
	const [audioURL, setAudioURL] = useState('');
	const [audioBlob, setAudioBlob] = useState('');
	const [voiceData, setVoiceData] = useState()
	const [transactionData, setTransactionData] = useState([]);
	const [expanded, setExpanded] = useState();
	const match = useMediaQuery('(min-width: 900px)');
	const [loaded, setLoaded] = useState();
	const { themeObj } = useTheme();
	const { mongoUser } = useAuth();
	const [homeStats, setHomeStats] = useState({
		inventory_summary: {},
		sales_overview: {},
		profit_estimate: {},
		top_selling_items: {}
	});
	const [statsLoaded, setStatsLoaded] = useState(false);

	const handleAudioSubmit = () => {
		if (audioBlob) {
			const reader = new FileReader();
			reader.readAsDataURL(audioBlob);
			reader.onloadend = async function () {
				let base64String = reader.result;
				const res = await langaugeApi.synthesizeAudioTranslate(base64String.slice(base64String.indexOf(',') + 1), 'hi', 'en'); // can change
				const en = await langaugeApi.synthesizeAudioTranslate(base64String.slice(base64String.indexOf(',') + 1), 'en', 'en');
				fetch(
					`${process.env.REACT_APP_BACKEND_URL}/audio/all_in_one/MUM1957MH/hi`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							"Access-Control-Allow-Origin": "*",
						},
						body: JSON.stringify({ audio_content: { en: res.final_output, hi: en.final_output }, initial_page: '/dashboard' })
					}
				).then(response => {
					if (!response.ok) {
						console.log(`HTTP error! status: ${response.status}`);
					}
					return response.json();
				}).then(data => {
					setVoiceData(data);
				}).catch(err => {
					console.log(err);
				})
			}
		}
	}

	useEffect(() => {
		const getData = async () => {
			const response = await getHomeStatistics();
			if (response.success) {
				console.log(response.data.data);
				setHomeStats(response.data.data);
			}
			setStatsLoaded(true);
		};
		getData();
	}, []);

	useEffect(() => {
		if (voiceData) {
			switch (voiceData.function_name) {
				case "view_inventory":
					setRedirection('/inventory', { filters: voiceData.filters, itemCategory: voiceData.item_category });
					break;
				case "show_transaction":
					setRedirection('/transactions', { duration: voiceData.duration, itemCategory: voiceData.item_category, filters: voiceData.transaction_type });
					break
				case "generate_report":
					setRedirection('/report', {});
					break;
				case "add_transaction":
					setRedirection('/barcode', { itemList: voiceData.item_list, transactionType: voiceData.transaction_type });
					break;
				default:
					break;
			}
		}
	}, [voiceData])

	useEffect(() => {
		setLoaded(false);
		const getData = async () => {
			const options = {
				page: 1,
				pageSize: 5,
			};
			const response = await viewTransactions(options)
			if (response.success) {
				setTransactionData(response.data.data.transactions);
			}
			else {
				console.error(response.message);
			}
			setLoaded(true);
		}
		getData();
		setRedirection({});
	}, [])

	return (
		<Stack rowGap={2}>
			{/* <Stack
				direction={match ? 'row' : 'column'}
				divider={<Divider variant="middle" orientation={match ? 'vertical' : 'horizontal'} flexItem />}
				justifyContent="center"
				alignItems="stretch"
				spacing={2}
			>
				<Box
					flexBasis='50%'
					display='flex'
					justifyContent='center'
					alignItems='center'
				>
					<Stack
						flexDirection='row'
						gap={2}
						alignItems='center'
					>
						{audioURL ?
							<CustomMediaPlayer submit={handleAudioSubmit} mediaUrl={audioURL} clearAudio={() => setAudioURL('')} />
							:
							<Recorder setAudioURL={setAudioURL} setAudioBlob={setAudioBlob} />
						}
					</Stack>
				</Box>
			</Stack> */}
			<Stack>
				<Typography sx={{ mt: 2, fontSize: '1.5rem' }} width="100%" color={themeObj.subtitle}>Good Afternoon</Typography>
				<Typography sx={{ mb: 2, fontSize: '2.5rem' }} width="100%" color={themeObj.title}>{mongoUser?.name}</Typography>
			</Stack>
			<Stack gap={1}>
				{/* <Divider variant="middle" sx={{ marginBottom: '5px', "&::before, &::after": { borderColor: themeObj.subtitle } }}>
					<Typography color={themeObj.title} fontSize="1.2rem">Statistics</Typography>
				</Divider> */}
				{/* <StatisticItem data={[{ name: "Sale", value: "₹ 8723" }, { name: "Purchase", value: "₹ 5234" }]} link="/transactions" linkName="Transactions" />
				<StatisticItem data={[{ name: "Cash", value: "₹ 424" }, { name: "Online", value: "₹ 685" }]} link="/report" linkName="Reports Page" /> */}
				<HomeStats data={homeStats} loaded={statsLoaded}/>
			</Stack>
			{/* <Stack gap={1}>
				<Divider variant="middle" sx={{ marginBottom: '5px', "&::before, &::after": { borderColor: themeObj.subtitle } }}>
					<Typography color={themeObj.title} fontSize="1.2rem">Last 5 Transactions</Typography>
				</Divider>
				<TransitionGroup>
					{loaded ?
						transactionData.length && transactionData.map(data =>
							<Collapse key={data.transaction_id}>
								<TransactionItem
									margin="5px"
									{...data}
									setExpanded={() => setExpanded(expanded === data.transaction_id ? null : data.transaction_id)}
									expanded={expanded === data.transaction_id}
									label={messages.transaction.chips[data.transaction_type]}
								/>
							</Collapse>
						)
						:
						Array.from({ length: 5 }, (e, i) => (
							<Collapse key={i}>
								<TransactionItemLoader />
							</Collapse>
						))
					}
				</TransitionGroup>
				{transactionData.length === 0 && loaded && <Typography>No Transactions Yet</Typography>}
			</Stack> */}
			<div style={{ height: "10px"}} />
		</Stack>
	);
}
