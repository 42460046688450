import { Box, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "../../context/ThemeContext";

export default function Item({ data }) {
	const { themeObj } = useTheme();
	return (
		<Paper sx={{ width: "100%", padding: "10px 15px", backgroundColor: themeObj.secondary }}>
			<Typography color={themeObj.title} fontSize="1.2rem">{data.item_name}</Typography>
			<Typography color={themeObj.title} fontSize="0.9rem">Quantity Sold: {data.quantity}</Typography>
			<Stack sx={{ marginTop: "10px" }}>
				<Grid container spacing={1}>
					<Grid item xs={6} sx={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
						<Typography color={themeObj.subtitle} fontWeight={600} fontSize="0.9rem">Subtotal</Typography>
					</Grid>
					<Grid item xs={6} sx={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'flex-end' }}>
						<Typography color={themeObj.title} fontSize="1rem">₹ {data.subtotal.toFixed(2)}</Typography>
					</Grid>
				</Grid>
				<Grid container spacing={1}>
					<Grid item xs={6} sx={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
						<Typography color={themeObj.subtitle} fontWeight={600} fontSize="0.7rem">GST Amount ({((data.gst_rate || 0) * 100).toFixed(2)} %)</Typography>
					</Grid>
					<Grid item xs={6} sx={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'flex-end' }}>
						<Stack justifyContent="space-between" alignItems="center" direction="row" gap={1}>
							<Typography color={themeObj.subtitle} fontSize="1rem">+</Typography>
							<Typography color={themeObj.title} fontSize="1rem">₹ {data.gst_amount.toFixed(2)}</Typography>
						</Stack>
					</Grid>
				</Grid>
				<Grid container spacing={1}>
					<Grid item xs={6} sx={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
						<Typography color={themeObj.subtitle} fontWeight={600} fontSize="0.7rem">Discount</Typography>
					</Grid>
					<Grid item xs={6} sx={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'flex-end' }}>
						<Stack justifyContent="space-between" alignItems="center" direction="row" gap={1}>
							<Typography color={themeObj.subtitle} fontSize="1rem">-</Typography>
							<Typography color={themeObj.title} fontSize="1rem">₹ {data.discount_amount.toFixed(2)}</Typography>
						</Stack>
					</Grid>
				</Grid>
				<Divider sx={{ borderColor: themeObj.subtitle, my: 1 }} />
				<Grid container spacing={1}>
					<Grid item xs={6} sx={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
						<Typography color={themeObj.subtitle} fontWeight={600} fontSize="0.9rem">Total</Typography>
					</Grid>
					<Grid item xs={6} sx={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'flex-end' }}>
						<Typography color={themeObj.title} fontSize="1rem">₹ {data.total_price.toFixed(2)}</Typography>
					</Grid>
				</Grid>
			</Stack>
		</Paper>
	);
}