import React from "react";
import { Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import BurgerMenu from "./BurgerMenu";
import logo from '../../assets/logo-white.png'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import krayaLogo from '../../assets/krayaLogoWhite.jpg'
import { useTheme } from "../../context/ThemeContext";
import { useNavigate } from "react-router-dom";

export default function MobileNavbar() {
	const { themeObj } = useTheme();
	const navigate = useNavigate();

	return (
		<Grid sx={{ backgroundColor: themeObj.card, borderRadius: "20px", border: "1px solid", borderColor: themeObj.secondary, boxShadow: "0 4px 6px -1px black", paddingLeft: 1, paddingRight: 1 }} container alignItems='center' justifyContent='space-between'>
			<Grid item xs={9} container gap={1} alignItems="center">
				<Button disableRipple sx={{ p: 0, textTransform: "none" }} onClick={() => navigate('/dashboard')}>
					<Stack direction="row" gap={1} alignItems="center">
						<img src={krayaLogo} alt="" height={30} width={30} style={{ borderRadius: "50%" }} />
						<Typography sx={{ fontSize: "1.2rem" }} color={themeObj.contrast} fontWeight={500}>Kraya</Typography>
					</Stack>
				</Button>
			</Grid>
			{/* <Grid item xs={1.5} container justifyContent='right'>
				<IconButton><NotificationsNoneOutlinedIcon htmlColor={themeObj.primary} fontSize="medium" sx={{ p: 0 }} /></IconButton>
			</Grid> */}
			<Grid item xs={1.5} container justifyContent='left'>
				<BurgerMenu />
			</Grid>
		</Grid>
	);
};