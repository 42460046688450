import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { Stack, Paper, Typography, Box, TextField, MenuItem } from "@mui/material";
import ProfileItem from "../components/Profile/ProfileItem";
import storePic from "../assets/shop.webp";
import TopSection from "../components/Profile/TopSection";
import { useTheme } from "../context/ThemeContext";
import ProfileItemEdit from "../components/Profile/ProfileItemEdit";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { updateStore } from "../apis/storeApi";
import { updateUser } from "../apis/userApi";

const validationSchema = Yup.object({
	store_name: Yup.string().required('Store name is required'),
	store_category: Yup.string().required('Category is required'),
	pincode: Yup.number().typeError('PIN code should be a number'),
	name: Yup.string().required('Name is required'),
});

export default function ProfilePage() {
	const navigate = useNavigate();
	const { themeObj } = useTheme();
	const { mongoUser, store, updateStoreLocal, updateUserLocal } = useAuth();
	const [isEditing, setIsEditing] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	// useEffect(() => {
	// 	if (!mongoUser) {
	// 		navigate('/onboarding')
	// 	}
	// }, []);

	const formik = useFormik({
		initialValues: {
			store_name: store.store_name ?? '',
			store_category: store.store_category ?? '',
			phone: store.phone ?? '',
			address: store.address?.address ?? '',
			city: store.address?.city ?? '',
			state: store.address?.state ?? '',
			pincode: store.address?.pincode ?? '',
			upi_id: store.upi_id ?? '',
			store_gstin: store.store_gstin ?? '',
			name: mongoUser?.name ?? '',
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			try {
				setIsSaving(true);
				const payload = {
					...store,
					store_name: values.store_name,
					store_category: values.store_category,
					phone: values.phone,
					upi_id: values.upi_id,
					address: {
						...store.address,
						address: values.address,
						city: values.city,
						state: values.state,
						pincode: values.pincode,
					},
				};

				console.log('Submitting:', payload);
				const response = await updateStore(mongoUser._id, payload);
				if (response.success) {
					setIsEditing(false);
					updateStoreLocal(payload);
					// Optionally refresh data or show success message
				} else {
					// Handle error
					console.error('Failed to update store');
				}
			} catch (error) {
				console.error('Error updating store:', error);
			} finally {
				setIsSaving(false);
			}
			try {
				if (mongoUser.name === values.name) return; 
				setIsSaving(true);
				const payload = {
					...mongoUser,
					name: values.name,
				};

				console.log('Submitting:', payload);
				const response = await updateUser(mongoUser._id, payload);
				if (response.success) {
					setIsEditing(false);
					updateUserLocal(payload);
					// Optionally refresh data or show success message
				} else {
					// Handle error
					console.error('Failed to update user');
				}
			}
			catch (error) {
				console.error('Error updating user:', error);
			}
			finally {
				setIsSaving(false);
			}
		},
	});

	const renderField = (label, value, name) => {
		if (isEditing) {
			return (
				<ProfileItemEdit
					heading={label}
					name={name}
					placeholder={label}
					value={formik.values[name]}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched[name] && Boolean(formik.errors[name])}
					helperText={formik.touched[name] && formik.errors[name]}
				/>
			);
		}
		return <ProfileItem label={label} value={value} />;
	}

	return (
		<Stack gap={1}>
			<TopSection
				storePic={storePic}
				profilePic={mongoUser?.picture}
				name={mongoUser?.name}
				email={mongoUser?.email}
				isEditing={isEditing}
				setIsEditing={setIsEditing}
				submit={async () => { await formik.submitForm() }}
				formik={formik}
			/>
			{Object.values(formik.errors).length && <div>{Object.values(formik.errors).join(', ')}</div>}
			{isEditing ?
				<Paper sx={{ width: "100%", padding: "10px 15px", backgroundColor: themeObj.card, display: "flex", flexDirection: "column", gap: "10px" }}>
					<TextField
						size="small"
						variant="standard"
						fullWidth
						name="store_name"
						placeholder="Store Name"
						value={formik.values.store_name}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.store_name && Boolean(formik.errors.store_name)}
						helperText={formik.touched.store_name && formik.errors.store_name}
						InputProps={{
							sx: {
								fontSize: '1.2rem',
								py: 0,
								color: themeObj.contrast,
								'&::before': {
									borderBottomColor: themeObj.subtitle
								},
								'&::after': {
									borderBottomColor: themeObj.primary
								},
								'& input': {
									py: 0
								}
							},
						}}
					/>
					<TextField
						select
						size="small"
						variant="standard"
						fullWidth
						name="store_category"
						placeholder="Store Category"
						value={formik.values.store_category}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.store_category && Boolean(formik.errors.store_category)}
						helperText={formik.touched.store_category && formik.errors.store_category}
						sx={{
							'& div:first-of-type': {
								p: 0
							},
							'& .MuiSelect-icon': {
								color: themeObj.title
							}
						}}
						InputProps={{
							sx: {
								fontSize: '0.7rem',
								color: themeObj.contrast,
								'&::before': {
									borderBottomColor: themeObj.subtitle
								},
								'&::after': {
									borderBottomColor: themeObj.primary
								}
							},
						}}
						SelectProps={{

							MenuProps: {
								sx: {
									'& .MuiMenu-paper': {
										backgroundColor: themeObj.card,
									},
									'& .MuiMenu-list': {
										p: 0
									}
								}
							}
						}}
					>
						<MenuItem sx={{ fontSize: "0.7rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} value="Supermarket">Super Market</MenuItem>
						<MenuItem sx={{ fontSize: "0.7rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} value="Electronics">Electronics</MenuItem>
					</TextField>
				</Paper>
				:
				<Paper sx={{ width: "100%", padding: "10px 15px", backgroundColor: themeObj.secondary }}>
					<Typography color={themeObj.title} fontWeight={500} fontSize="1.2rem">{store?.store_name}</Typography>
					<Typography color={themeObj.subtitle} fontSize="0.7rem">{store?.store_category}</Typography>
				</Paper>
			}
			{renderField('Address', store?.address?.address, 'address')}
			<Stack gap={1} flexDirection="row">
				{renderField('City', store?.address?.city, 'city')}
				{renderField('State', store?.address?.state, 'state')}
			</Stack>
			<Stack gap={1} flexDirection="row">
				{renderField('PIN Code', store?.address?.pincode, 'pincode')}
				{renderField('Phone', store?.phone, 'phone')}
			</Stack>
			{renderField('UPI ID', store?.upi_id, 'upi_id')}
			{renderField('GSTIN', store?.store_gstin, 'store_gstin')}
			<Box sx={{ height: "20px" }}></Box>
		</Stack>
	);
}