import { Paper, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "../../context/ThemeContext";

export default function VendorDetailField({ label, value }) {
	const { themeObj } = useTheme();
	return (
		<Paper sx={{ width: "100%", padding: "10px 15px", backgroundColor: themeObj.secondary }}>
			<Typography color={themeObj.subtitle} fontWeight={600} fontSize="0.7rem">{label}</Typography>
			<Typography color={themeObj.title} fontSize="1rem">{value}</Typography>
		</Paper>
	);
}